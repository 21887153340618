import React, { useRef } from "react";
import './works.css';
import choice from '../../static/choice.png';
import edit from '../../static/edit.png';
import share from '../../static/share.png';
import { useIntersection } from "../utils/onScreen";

function Works() {
  const ref1 = useRef();
  const isInViewport1 = useIntersection(ref1, '0px');

  return (
    <section className="section how-it-works">
      <div className="max-w-[1320px] w-full mx-auto px-[0.75rem]">
        <div className="row text-center work-row">
          <h1 className="mb-[80px] fos-animate-me fadeInUp delay-0_9">
             Three Simple Functions to <span className="text-[#F66962]"> Learn Together </span>
          </h1>
          <div id="svg-container">
            <svg
              id="svgC"
              width="100%"
              height="100%"
              viewBox="0 0 620 120"
              preserveAspectRatio="xMidYMid meet"
            >
              <desc>Created with Snap</desc>
              <defs></defs>
              <path
                d="M62.9 14.9c-25-7.74-56.6 4.8-60.4 24.3-3.73 19.6 21.6 35 39.6 37.6 42.8 6.2 72.9-53.4 116-58.9 65-18.2 191 101 215 28.8 5-16.7-7-49.1-34-44-34 11.5-31 46.5-14 69.3 9.38 12.6 24.2 20.6 39.8 22.9 91.4 9.05 102-98.9 176-86.7 18.8 3.81 33 17.3 36.7 34.6 2.01 10.2.124 21.1-5.18 30.1"
                id="squiggle"
                fill="none"
                stroke="rgba(0,0,0,0.1)"
                strokeMiterlimit="10"
                strokeDashoffset="180"
                className="SvgTr"
              ></path>
              <g transform="matrix(0.2956,-0.9553,0.9553,0.2956,43.2367,24.8965)"></g>
              <g>
                transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"&gt;
              </g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,557,57)"></g>
              <g transform="matrix(-0.8615,-0.5077,0.5077,-0.8615,577.307,92.5386)">
                <polyline
                  points="0, 30, 15, 0, 30, 30"
                  id="plane"
                  fill="rgba(0,0,0,0.05)"
                  className="text-red-600"
                ></polyline>
              </g>
            </svg>
          </div>
          <div ref={ref1} className={"col-lg-4 col-md-12" + ((isInViewport1) ? " fos-animate-me fadeIn delay-0_1" : "")}>
            <div className="work-process relative">
              <div className="box-loader">
                {" "}
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="step-num-box">
                <div className="step-icon">
                  <span>
                    <img src={choice} className="w-[80px]" />
                  </span>
                </div>
                <div className="step-num text-[#222F5A]">
                  01
                </div>
              </div>
              <div className="step-desc">
                <h4>Explore Books</h4>
                <p className="mb-0">Explore the learning resources</p>
              </div>
            </div>
          </div>
          <div className={"col-lg-4 col-md-12 md-mt-5" + ((isInViewport1) ? " fos-animate-me fadeIn delay-0_2" : "")}>
            <div className="work-process relative">
              <div className="box-loader">
                {" "}
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="step-num-box">
                <div className="step-icon">
                  <span>
                    <img src={edit} className="w-[80px]" />
                  </span>
                </div>
                <div className="step-num text-[#222F5A]">
                  02
                </div>
              </div>
              <div className="step-desc">
                <h4>Write Knowledge</h4>
                <p className="mb-0">Write off the knowledge in your mind</p>
              </div>
            </div>
          </div>
          <div className={"col-lg-4 col-md-12 md-mt-5" + ((isInViewport1) ? " fos-animate-me fadeIn delay-0_3" : "")}>
            <div className="work-process relative">
              <div className="step-num-box">
                <div className="step-icon">
                  <span>
                    <img src={share} className="w-[80px]" />
                  </span>
                </div>
                <div className="step-num text-[#222F5A]">
                  03
                </div>
              </div>
              <div className="step-desc">
                <h4>Share Education</h4>
                <p className="mb-0">Share your knowledge to help others</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Works;
